module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["G-EDRX15YQ2M"],"pluginConfig":{"head":true}},
    },{
      plugin: require('../node_modules/gatsby-plugin-facebook-pixel/gatsby-browser.js'),
      options: {"plugins":[],"pixelId":"XXXXXXXXXXXXXXX"},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Robert Kossak","short_name":"Robert Kossak","start_url":"/","background_color":"#F86900","theme_color":"#F86900","display":"standalone","icon":"src/images/icon.png","icons":[{"src":"/favicons/icon-512x512.png","sizes":"512x512","type":"image/png"},{"src":"/favicons/icon-384x384.png","sizes":"384x384","type":"image/png"},{"src":"/favicons/icon-256x256.png","sizes":"256x256","type":"image/png"},{"src":"/favicons/icon-192x192.png","sizes":"192x192","type":"image/png"},{"src":"/favicons/icon-144x144.png","sizes":"144x144","type":"image/png"},{"src":"/favicons/icon-96x96.png","sizes":"96x96","type":"image/png"},{"src":"/favicons/icon-72x72.png","sizes":"72x72","type":"image/png"},{"src":"/favicons/icon-48x48.png","sizes":"48x48","type":"image/png"},{"src":"/favicons/icon-32x32.png","sizes":"32x32","type":"image/png"},{"src":"/favicons/icon-16x16.png","sizes":"16x16","type":"image/png"}],"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"c5efda9e3b7accab5ccc376dbfc9abfc"},
    },{
      plugin: require('../node_modules/gatsby-plugin-web-font-loader/gatsby-browser.js'),
      options: {"plugins":[],"google":{"families":["Raleway:100,300,400,500,700,900"]}},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
